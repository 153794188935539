import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './pages.css'

const ProjectSlider = () => {
    useEffect(() => {
        AOS.init({duration: 1000});
      }, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Auto-play interval in milliseconds (3 seconds in this example)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className="container-slider">
    <Slider {...settings}>
      {/* Your individual slides here */}
      <div data-aos="fade-up" className=' max-width cursor-pointer project-card rounded overflow-hidden shadow-lg transition-transform duration-300 bord ease-in-out transform hover:scale-105'>
        <img className="w-full" src="https://panda.axiomthemes.com/wp-content/uploads/2020/05/portf14-copyright-890x664.jpg" alt="Cardimage"/>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Simple Project</div>
          <p className="text-gray-700 text-base">
            React JS - Tailwind    </p>
        </div>
      </div>
      <div data-aos="fade-up" className='max-width cursor-pointer project-card rounded overflow-hidden shadow-lg transition-transform duration-300 bord ease-in-out transform hover:scale-105'>
        <img className="w-full" src="https://panda.axiomthemes.com/wp-content/uploads/2020/05/portf14-copyright-890x664.jpg" alt="Cardimage"/>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Simple Project</div>
          <p className="text-gray-700 text-base">
            React JS - Tailwind
          </p>
        </div>
      </div>
      <div data-aos="fade-up" className='max-width cursor-pointer project-card rounded overflow-hidden shadow-lg transition-transform duration-300 bord ease-in-out transform hover:scale-105'>
        <img className="w-full" src="https://panda.axiomthemes.com/wp-content/uploads/2020/05/portf14-copyright-890x664.jpg" alt="Cardimage"/>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Simple Project</div>
          <p className="text-gray-700 text-base">
            React JS - Tailwind
          </p>
        </div>
      </div>
      <div data-aos="fade-up" className='max-width cursor-pointer project-card  rounded overflow-hidden shadow-lg transition-transform duration-300 bord ease-in-out transform hover:scale-105'>
        <img className="w-full" src="https://panda.axiomthemes.com/wp-content/uploads/2020/05/portf14-copyright-890x664.jpg" alt="Cardimage"/>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Simple Project</div>
          <p className="text-gray-700 text-base">
            React JS - Tailwind
          </p>
        </div>
      </div>
      
      {/* Add more slides as needed */}
    </Slider>
    </div>
  );
};

export default ProjectSlider;
