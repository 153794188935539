import React, { useEffect } from 'react'
import Navbar from '../navbar/Navbar'
import './pages.css'
import Aos from 'aos'
import Lottie from 'react-lottie'
import officwork from '../../asset/howwrok.json'
import Footer from './Footer'
import { motion } from 'framer-motion';
// import CountUp from 'react-countup/build/CountUp'
// eslint-disable-next-line no-unused-vars
import CountUp from 'react-countup';
import img1 from '../images/img-about.png';
import img2 from '../images/Raj Rani Spices.png';
import img3 from '../images/Bright.jpg';
import bright_logo from '../images/img-about.png';
import Team from './Team'
import aboutimg from '../images/howtowork.png';



const About = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: officwork,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  
    useEffect(() => {
      Aos.init({duration: 2000 , offset: 200, once: true});
       
    }, [])
  return (
    <>
    <Navbar/>
    <motion.div 
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
    >
    <div data-aos="fade-down" className="service-main my-4">
      <div className="servicegrid my-4">
        <h1>Handcrafted projects</h1>
        <p>We specialize in crafting meticulously tailored projects. From conception to execution, our team applies artisanal precision and innovative flair to deliver unique digital solutions that exceed expectations.</p>
      </div>
    </div>
    
<div className="margin"></div>
<div className="flex justify-center" style={{ backgroundColor: 'ghostwhite' }}>
      <div className="grid md:grid-cols-2 md:gap-4 sm:grid-cols-1 sm:gap-2" style={{ backgroundColor: 'ghostwhite' }}>
        <div className="my-4 p-4 text-center">
          <h1 className="text-6xl font-bold">
            <CountUp start={0} end={1} duration={2} />+
          </h1>
          <p>Years of experience</p>
        </div>
        <div className="my-4 p-4 text-center">
          <h1 className="text-6xl font-bold">
            <CountUp start={0} end={5} duration={2.5} />+
          </h1>
          <p>Projects delivered</p>
        </div>
        {/* <div className="my-4 p-4 text-center">
          <h1 className="text-6xl font-bold">
            <CountUp start={0} end={30} duration={2} />+
          </h1>
          <p>Reviews</p>
        </div> */}
      </div>
    </div>

    <div className="about-heading">
    <h1 className="text-5xl font-bold mb-3 text-center mt-20">How we work?</h1></div>
    <div className="web-container whitbg  grid grid-cols-1 gap-4 md:grid-cols-2 md:flex md:flex-row-reverse">
    <div data-aos="fade-up" className="img-about">
        <Lottie  options={defaultOptions} height={300} width={400} loop='true' isClickToPauseDisabled={true} />
      </div>
      <div data-aos="fade-up" className="web borders" style={{}}>
        {/* <h1>How we work?</h1> */}
        <p className="mt-2 ">
          <img src={aboutimg} alt=''></img>
          {/* <li>We're a remote first company with a cohesive team, working asynchronously to help people across the globe to build and scale their dream projects.</li>
          <li>We prefer documentation over talks and over communicating things to make them clear to everyone. ScaleReal believes in small teams as they are fast and nimble, which means less management and getting more things done.</li>
          <li>We follow agile methodology which involves sprint planning, daily updates, sprint retrospective, client demo, UAT apart from requirement gathering, design work, feature implementation, testing, deployment and maintenance.</li> */}
          </p>
      </div>
      
    </div>
    <div className='clients_page' style={{backgroundColor:'white'}}>
    <div data-aos="fade-up">
        <h1 className="text-5xl font-bold mb-3 text-center">Our Recent Clients</h1>
    <section className="clients">
        <div className="client">
            <img src={bright_logo} className='bright_logo' alt=""/>
        </div>
        <div class="client">
            <img src={img2} className='bright_logo2' alt=""/>
        </div>
    </section>
    </div>
    </div>
    <Team/>
      <Footer/>
    </motion.div>
    </>
  )
}

export default About