import React, { useEffect, useState } from 'react';
import './navbar.css';
import { NavLink } from 'react-router-dom';
import { MenuItem } from './MenuItem';
import header_logo from '../images/Asset 1xxhdpi.svg'

const Navbar = () => {
  const [btnClick, setBtnClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const handleClick = () => {
    setBtnClick(!btnClick);
  };

  const handleMenuItemClick = (index) => {
    setActiveLink(index);
    setTimeout(() => {
      setActiveLink(null);
      setBtnClick(false);
    }, 500); // Adjust the duration as needed
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <nav className={`NavbarItems ${scrolled ? 'scrolled' : ''}`}>
      <a href="/" className='navbar-logo'>
        <img src={header_logo} alt=''></img>
        <h1>Beliance Web</h1>
      </a>
      <div className='menu-icons' onClick={handleClick}>
        <i className={btnClick ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
      </div>
      <ul className={btnClick ? 'nav-menu active' : 'nav-menu'}>
        {MenuItem.map((item, index) => (
          <li 
            key={index} 
            onClick={() => handleMenuItemClick(index)}
            className={activeLink === index ? 'active-link' : ''}
          >
            <NavLink className={item.cName} to={item.url}>
              {item.title}
            </NavLink>
          </li>
        ))}
        {/* <li className='btn' onClick={() => handleMenuItemClick('contact')}>
          <NavLink to={'/contact'}>Let's Talk</NavLink>
        </li> */}
        <div className="contact-nav" onClick={() => handleMenuItemClick('contact')}>
          <hr/>
          <ul>
            <i className="fa-solid fa-phone" style={{color: "#0e1554"}}></i> +91 9512023735
          </ul>
          <ul>
            <i className="fa-regular fa-envelope"></i> info@belianceweb.com
          </ul>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
