import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Basic Swiper styles
import 'swiper/css/effect-coverflow'; // Effect Coverflow styles
import 'swiper/css/pagination'; // Pagination styles
import 'swiper/css/navigation'; // Navigation styles
import { EffectCoverflow, Autoplay, Pagination, Navigation } from 'swiper/modules';
import './technology.css'; // Make sure this path is correct
import flutter from '../images/Flutter (1).png'
import laravel from '../images/Laravel.png'
import php from '../images/php.png'
import kotlin from '../images/Kotlin.png'
import reacts from '../images/react.png'
import shopify from '../images/shopify.png'
import swiftios from '../images/swiftios.png'
import tailwind from '../images/tailwind.png'
import vuejs from '../images/vuejs.png'
import wordpress from '../images/wordpress.png'
import bootstrap from '../images/bootstrap.png'
import net from '../images/net.png'



const slides = [
  { src: flutter, title: "Flutter" },
  { src: kotlin, title: "Kotlin" },
  { src: laravel, title: "Laravel" },
  { src: php, title: "PHP" },
  { src: reacts, title: "React js" },
  { src: shopify, title: "Shopify" },
  { src: swiftios, title: "Swiftios" },
  { src: tailwind, title: "Tailwind" },
  { src: vuejs, title: "Vue JS" },
  { src: wordpress, title: "Wordpress" },
  { src: bootstrap, title: "Bootstrap" },
  { src: net, title: ".net" },
];

const Technology = () => {
  return (
    <div className='html'>
    <section id="tranding">
      <h2 id="technology-heading">Beliance is dedicated through technologies that are</h2>
      <div className="containers-swipe">
        <Swiper
          className="tranding-slider"
          modules={[EffectCoverflow, Autoplay, Pagination, Navigation]}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 2,
          }}
          pagination={{ clickable: true }}
        //   navigation={true}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="tranding-slide">
              <div className="tranding-slide-img">
                <img src={slide.src} alt={slide.title} />
              </div>
              <div className="tranding-slide-content">
                <div className="tranding-slide-content-bottom">
                  <h2 className="food-name-h3">{slide.title}</h2>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
    </div>
  );
};

export default Technology;
