import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './pages.css';

const Form = () => {
  const [formErrors, setFormErrors] = useState({});

  const validateForm = (name, email, number, message) => {
    const errors = {};

    if (!name) errors.name = "Name is required.";
    if (!email) errors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) errors.email = "Email address is invalid.";
    if (!number) errors.number = "Phone number is required.";
    else if (!/^\d{10}$/.test(number)) errors.number = "Phone number must be 10 digits.";


    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Fetch values from form
    const { name, email, number, message } = e.target.elements;

    const errors = validateForm(name.value, email.value, number.value, message.value);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Your EmailJS template parameters
    const templateParams = {
      from_name: name.value,
      to_name: 'Dhruvesh Vora', // Change to recipient's name
      from_number: number.value,
      message: message.value,
      reply_to: email.value,
    };

    // Your EmailJS service ID, template ID, and user ID
    emailjs.send('service_g6ejcvv', 'template_5d8gbti', templateParams, 'x0IOXebOnYyCm38IN')
      .then((response) => {
        toast.success("We'll contact you soon.", {
          style: { marginTop: '23%' }, // Add margin top of 20%
        });
      })
      .catch((error) => {
        toast.error('Failed to send message. Please try again later.', {
          style: { marginTop: '23%' }, // Add margin top of 20%
        });
      });

    // Clear form fields after submission
    e.target.reset();
    setFormErrors({});
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="submit-contact-form" className="form-container">
      <div className="p-2 w-full">
          <div className="relative">
            <label htmlFor="name" className="leading-7 py-4 text-lg text-gray-900">Your Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              className="w-full bg-white rounded border border-gray-400 focus:border-blue-900 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out" 
              onKeyPress={(e) => {
                if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>}
          </div>
        </div>
        <div className="p-2 w-full">
          <div className="relative">
            <label htmlFor="number" className="leading-7 py-4 text-lg text-gray-900">Your Phone Number</label>
            <input 
              type="text" 
              id="number" 
              name="number" 
              max={'10'}
              min={'10'}
              className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out" 
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {formErrors.number && <p className="text-red-500 text-sm">{formErrors.number}</p>}
          </div>
        </div>
        <div className="p-2 w-full">
          <div className="relative">
            <label htmlFor="email" className="leading-7 py-4 text-lg text-gray-900">Your Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              
              className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out" 
            />
            {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
          </div>
        </div>
        <div className="p-2 w-full">
          <div className="relative">
            <label htmlFor="message" className="leading-7 py-4 text-lg text-gray-900">Your Message</label>
            <textarea 
              id="message" 
              name="message" 
              
              className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-900 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" 
            />
            {formErrors.message && <p className="text-red-500 text-sm">{formErrors.message}</p>}
          </div>
        </div>
        <div className="w-full">
          <button 
            type="submit"
            className="button flex justify-center items-center text-center text-white bg-blue-500 px-4 rounded-lg hover:bg-red-500">
            Send Message ✉
          </button>
        </div>
      </form>
    </>
  );
};

export default Form;
