import './App.css';
// import Navbar from './component/navbar/Navbar';
import { BrowserRouter, Routes, Route,NavLink } from "react-router-dom";
import About from './component/pages/About';
import Contact from './component/pages/Contact';
import Home from './component/pages/Home';
import Service from './component/pages/Service';
import Project from './component/pages/Project';
import Careers from './component/pages/Careers';
import { AnimatePresence } from 'framer-motion';
import ScrollToTop from './Scroll/ScrollToTop';
import Footer from './component/pages/Footer';
import Team from './component/pages/Team';
import Technology from './component/pages/Technology';
// import videobg from './asset/video-bg.mp4';


function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
        <AnimatePresence>
        {/* <nav>
          <NavLink exact to='/' activeClassName='active' className='nav-links'></NavLink>
          <NavLink to='/about' activeClassName='active' className='nav-links'></NavLink>
          <NavLink to='/contact' activeClassName='active' className='nav-links'></NavLink>
          <NavLink to='/service' activeClassName='active' className='nav-links'></NavLink>
          <NavLink to='/project' activeClassName='active' className='nav-links'></NavLink>

        </nav> */}
      <Routes>
      {/* <Route path="/" element={<Navbar />} ></Route> */}
      <Route exact path="/about" element={<About/>}></Route>
      <Route path="/contact" element={<Contact/>}></Route>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/service" element={<Service/>}></Route>
      {/* <Route path="/project" element={<Project/>}></Route> */}
      {/* <Route path="/careers" element={<Careers/>}></Route> */}

      </Routes>
      </AnimatePresence>
        </BrowserRouter>

        
    </>
  );
}

export default App;
