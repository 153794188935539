import React, { useEffect } from 'react'
import Navbar from '../navbar/Navbar'
import './pages.css'
import 'aos/dist/aos.css';
import AOS from 'aos';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Lottie from 'react-lottie';
import webdev from '../../asset/Webdev.json'
import uxui from '../../asset/uiux.json'
import appdev from '../../asset/appdeveloper2.json'
// import software_development from '../../asset/software_development.json'
import software from '../../asset/Webdeveloper.json'
import digital_marketing  from '../../asset/digital_marketing.json'
import erp_solution from '../../asset/erp_solution.json'
import Aos from 'aos';
// import Lottie from 'react-lottie';


const Service = () => {
  useEffect(() => {
    AOS.init({duration: 1000 , offset: 100});
     
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: webdev,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: uxui,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: appdev,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: digital_marketing,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  const defaultOptions5 = {
    loop: true,
    autoplay: true,
    animationData: software,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  const defaultOptions6 = {
    loop: true,
    autoplay: true,
    animationData: erp_solution,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  Aos.init({
    once:true
  })
  
  

  return (
    <>
      <Navbar/>
    <motion.div 
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1.5 }}
    >
    
    <div data-aos="fade-down" className="service-main">
      <div className="servicegrid">
        <h1>Diverse range of services</h1>
        <p>Welcome to Beliance Web! Elevate your online presence with our expert team and tailored solutions.</p>
      </div>
    </div>


{/* Web */}
<div className="margin"></div>
    <div className="web-container grid grid-cols-1 ">
    <div data-aos="fade-in" className="img-web">
            <Lottie style={{width:'90%' , height:'auto'}}  options={defaultOptions} loop='true' isClickToPauseDisabled={true}/>
        </div>
        <div data-aos="fade-up" className="web">
            <h1 >Web Development</h1>
            <p className="mt-2">At Beliance Web, we specialize in creating responsive, user-friendly websites tailored to your business needs. Our expert team handles everything from intuitive design to robust backend systems, ensuring your site is optimized for performance, security, and scalability. Trust Beliance Web to elevate your online presence with cutting-edge web development solutions.</p>
            <div className="list-icon grid grid-cols-2 gap-1 mt-4">
                <div className="item item1 flex items-center"><i className="fas fa-circle-check mr-2"></i> Front-End Coding</div>
                <div className="item item2 flex items-center"><i className="fas fa-circle-check mr-2"></i> Back-End Coding</div>
                <div className="item item3 flex items-center"><i className="fas fa-circle-check mr-2"></i> Git</div>
                <div className="item item4 flex items-center"><i className="fas fa-circle-check mr-2"></i> Testing and Debugging</div>
                <div className="item item5 flex items-center"><i className="fas fa-circle-check mr-2"></i> Problem Solving</div>
                <div className="item item6 flex items-center"><i className="fas fa-circle-check mr-2"></i> Creativity</div>
            </div>
        </div>
        
    </div>


    {/* UI/UX design */}
    <div className="web-container whitbg grid grid-cols-1 gap-4 md:grid-cols-2 md:flex md:flex-row-reverse ">
    <div data-aos="fade-down" className="img-web " >
      <Lottie   options={defaultOptions5} width={'100'} height={300} loop={true} className="lottie-animation"  isClickToPauseDisabled={true}/>
      </div>
      <div data-aos="fade-up" className="web">
        <h1 >Software Devlopment</h1>
        <p className="mt-2">Starting Point excels in software development, tailoring solutions to meet your business needs. Our team specializes in creating scalable, secure, and user-friendly software applications, including custom software, mobile apps, enterprise solutions, and cloud-based platforms. With a focus on innovation and quality, we deliver solutions that enhance efficiency and drive business growth. Partner with Starting Point for comprehensive software development, dedicated support, and a commitment to your success.</p>
        <div className="list-icon  grid grid-cols-2 gap-1 ">
          <div className="item item2 flex items-center"><i className="fas fa-circle-check mr-2"></i> Software design</div>
          <div className="item item3 flex items-center"><i className="fas fa-circle-check mr-2"></i> Implementation</div>
          <div className="item item4 flex items-center"><i className="fas fa-circle-check mr-2"></i> Bug fixes</div>
          <div className="item item6 flex items-center"><i className="fas fa-circle-check mr-2"></i> Testing procedures</div>
          <div className="item item1 flex items-center"><i className="fas fa-circle-check mr-2"></i> Software Testing</div>
          <div className="item item5 flex items-center"><i className="fas fa-circle-check mr-2"></i> Deploying</div>
        </div>
      </div>
      
    </div>

   
{/* App */}
<div className="web-container grid grid-cols-1 ">
<div data-aos="fade-down" className="img-web">
  <Lottie
    options={defaultOptions3}
    height={400}
    width={"90%"}
    loop={true}
    isClickToPauseDisabled={true}
    className="lottie-animation"
  />
</div>
      <div data-aos="fade-up" className="web">
        <h1 >App Development</h1>
        <p className="mt-2">At Beliance Web, our application development services deliver bespoke apps crafted to suit your unique business requirements. We engineer responsive, intuitive applications for multiple platforms, guaranteeing flawless performance and an exceptional user journey. From inception to launch, our skilled team ensures your app is fortified, scalable, and in sync with your objectives.</p>
        <div className="list-icon grid grid-cols-2 gap-1 mt-4">
          <div className="item item1 flex items-center"><i className="fas fa-circle-check mr-2"></i> User-Centric</div>
          <div className="item item2 flex items-center"><i className="fas fa-circle-check mr-2"></i> Modern Technology</div>
          <div className="item item3 flex items-center"><i className="fas fa-circle-check mr-2"></i> Application Security</div>
          <div className="item item4 flex items-center"><i className="fas fa-circle-check mr-2"></i> Social Integration</div>
          <div className="item item5 flex items-center"><i className="fas fa-circle-check mr-2"></i> Easy Payment Gateway</div>
          <div className="item item6 flex items-center"><i className="fas fa-circle-check mr-2"></i> Android & IOS</div>
        </div>
      </div>
      
      
     
    </div>

    <div className="web-container whitbg grid grid-cols-1 gap-4 md:grid-cols-2 md:flex md:flex-row-reverse ">
    <div data-aos="fade-down" className="img-web " >
      <Lottie options={defaultOptions6}  width={"100%"} height={300} loop={true} isClickToPauseDisabled={true}/>
      </div>
      <div data-aos="fade-up" className="web">
        <h1 >ERP Solution</h1>
        <p className="mt-2">At Belicace, we provide customizable ERP solutions to streamline your business processes. Our systems integrate finance, HR, supply chain, inventory, CRM, and more into one platform, offering real-time data and analytics. Scalable, user-friendly, and secure, our ERP solutions enhance efficiency and collaboration. We serve diverse industries and offer dedicated support to ensure your success.</p>
        <div className="list-icon  grid grid-cols-2 gap-1 mt-4">
          <div className="item item1 flex items-center"><i className="fas fa-circle-check mr-2"></i> System integration</div>
          <div className="item item2 flex items-center"><i className="fas fa-circle-check mr-2"></i> Increased productivity</div>
          <div className="item item3 flex items-center"><i className="fas fa-circle-check mr-2"></i> Regulatory compliance</div>
          <div className="item item4 flex items-center"><i className="fas fa-circle-check mr-2"></i> Customer service</div>
          <div className="item item5 flex items-center"><i className="fas fa-circle-check mr-2"></i> Scalability</div>
          <div className="item item6 flex items-center"><i className="fas fa-circle-check mr-2"></i> Security</div>
        </div>
      </div>
      
    </div>


    <div className="web-container grid grid-cols-1 ">
<div data-aos="fade-down" className="img-web">
      <Lottie options={defaultOptions4} height={400} width={"100%"} loop={true} isClickToPauseDisabled={true}/>
      </div>
      <div data-aos="fade-up" className="web">
        <h1 >Digital Marketing</h1>
        <p className="mt-2">At Beliance Web, we provide tailored digital marketing services to elevate your brand and drive results. Our expertise includes SEO, social media, search engines, email, and content creation. We develop personalized strategies that align with your business goals, leveraging advanced analytics to optimize campaigns. Our services boost your online visibility, enhance engagement, and convert leads into loyal customers. Partner with Beliance Web for innovative digital marketing solutions that maximize your online presence.</p>
        <div className="list-icon grid grid-cols-2 gap-1 mt-4">
          <div className="item item1 flex items-center"><i className="fas fa-circle-check mr-2"></i> SEO</div>
          <div className="item item2 flex items-center"><i className="fas fa-circle-check mr-2"></i> SEM</div>
          <div className="item item3 flex items-center"><i className="fas fa-circle-check mr-2"></i> SMO</div>
          <div className="item item4 flex items-center"><i className="fas fa-circle-check mr-2"></i> SMM</div>
          <div className="item item5 flex items-center"><i className="fas fa-circle-check mr-2"></i> Contact Marketing</div>
          <div className="item item6 flex items-center"><i className="fas fa-circle-check mr-2"></i> Email Marketing 
          </div>
        </div>
      </div>     
    </div>


    <div className="web-container whitbg grid grid-cols-1 gap-4 md:grid-cols-2 md:flex md:flex-row-reverse ">
    <div data-aos="fade-down" className="img-web ">
      <Lottie options={defaultOptions2} height={300} width={'100%'} className="lottie-animation"  loop={true} isClickToPauseDisabled={true} />
      </div>
      <div data-aos="fade-up" className="web">
        <h1 >UI/UX Design</h1>
        <p className="mt-2">Beliance Web is fully responsible for UI/UX design services and it's create visually appealing and user-friendly interfaces. We prioritize usability and engagement, ensuring smooth and satisfying user experiences. Trust us for custom UI/UX solutions that look great and work seamlessly.</p>
        <div className="list-icon  grid grid-cols-2 gap-1 mt-4">
          <div className="item item1 flex items-center"><i className="fas fa-circle-check mr-2"></i> User-Centered Design</div>
          <div className="item item2 flex items-center"><i className="fas fa-circle-check mr-2"></i> Visual Design Principles</div>
          <div className="item item3 flex items-center"><i className="fas fa-circle-check mr-2"></i> Responsive Design</div>
          <div className="item item4 flex items-center"><i className="fas fa-circle-check mr-2"></i> Information Architecture</div>
          <div className="item item5 flex items-center"><i className="fas fa-circle-check mr-2"></i> Prototyping Tools</div>
          <div className="item item6 flex items-center"><i className="fas fa-circle-check mr-2"></i> Accessibility</div>
        </div>
      </div>
    </div>

    <div  className="idea">
      <div className="idea-bulb">
        <div className="bulb">
        <i className="fa-regular fa-lightbulb"></i>
        </div>
        <div className="content">
          <h1>Do You have an idea?</h1>
          <p>We can help you with Estimation, Prototype, Minimum Viable Product, Complete Solution for new and existing product.</p>
          <Link className='button' to={'/contact'}>Let's talk</Link>
        </div>
        </div>
    </div>


    <Footer/>
    </motion.div>
    </>

  )
}

export default Service